import React from 'react';

export function useDesktopBreakpoint() {
    const [isDesktop, setIsDesktop] = React.useState(false);

    React.useEffect(() => {
        const mediaQuery = window.matchMedia(breakpoints[`--sm`]);
        setIsDesktop(mediaQuery.matches);

        function handleBreakpointChange(ev: MediaQueryListEvent) {
            setIsDesktop(ev.matches);
        }

        addBreakpointListener(mediaQuery, handleBreakpointChange);
        return () => removeBreakpointListener(mediaQuery, handleBreakpointChange);
    }, []);

    return isDesktop;
}

function addBreakpointListener(
    query: MediaQueryList,
    handleBreakpointChange: (ev: MediaQueryListEvent) => void
) {
    if (query.addEventListener) {
        query.addEventListener('change', handleBreakpointChange);
    } else {
        // Fallback to deprecated API for older Safari browsers (prior to v14)
        query.addListener(handleBreakpointChange);
    }
}

function removeBreakpointListener(
    query: MediaQueryList,
    handleBreakpointChange: (ev: MediaQueryListEvent) => void
) {
    if (query.removeEventListener) {
        query.removeEventListener('change', handleBreakpointChange);
    } else {
        // Fallback to deprecated API for older Safari browsers (prior to v14)
        query.removeListener(handleBreakpointChange);
    }
}


const breakpoints = {
    '--sm': '(min-width: 480px)',
    '--md': '(min-width: 768px)',
    '--lg': '(min-width: 990px)',
    '--max480': '(max-width: 479px)',
    '--min480': '(min-width: 480px)',
    '--max768': '(max-width: 767px)',
    '--min768': '(min-width: 768px)',
    '--max990': '(max-width: 989px)',
    '--min990': '(min-width: 990px)',
};
