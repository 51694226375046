import createHeartComponent from '@finn-no/favorite-heart-component'
import React from 'react'
import { useDesktopBreakpoint } from './hooks/useDesktopBreakpoint'

export default function SaveAd({
    finncode,
    size,
    loginId,
}: {
    finncode: number
    size: 'SMALL' | 'MEDIUM' | 'LARGE'
    loginId: string
    favoriteApiUrl: string
}) {
    const isDesktop = useDesktopBreakpoint()
    const shouldRenderSmallHeart = !isDesktop && size === 'SMALL'

    const Heart = createHeartComponent({ userId: loginId })

    // TODO decide if mobile device
    return (
        <Heart
            itemType="Ad"
            itemId={finncode}
            isMobile={!isDesktop}
            variant={shouldRenderSmallHeart ? 'heart' : 'heart-on-image'}
            buttonClass="sf-heart-icon"
        ></Heart>
    )
}
