import { useCallback, useEffect, useState } from 'react'
import { MessageBus, Event } from '@podium/browser'
import { AdOfTheWeek } from '../ukensBoligService'

let messageBus = new MessageBus()

type FilterState = {
    state: 'initial' | 'pending' | 'success' | 'error'
    ukensBolig?: AdOfTheWeek
    errorMessage?: string
}

const initialState = (ukensBolig: AdOfTheWeek): FilterState => ({
    state: 'initial',
    ukensBolig
})

export function useFilterUpdated(initialData: AdOfTheWeek, apiUrl: string) {
    const [filterState, setFilterState] = useState<FilterState>(
        initialState(initialData)
    )

    const fetchData = useCallback(
        async (message: Event) => {
            setFilterState({ state: 'pending' })

            try {
                const url = new URL(apiUrl)
                url.search = createQueryString(
                    message.payload as object
                ).toString()
                const response = await fetch(url)
                const data = await response.json()

                if (response.ok) {
                    setFilterState({
                        state: 'success',
                        ukensBolig: {
                            polePosition: data.polePosition,
                            companyProfile: data.companyProfile
                        }
                    })
                } else {
                    throw new Error('Failed to fetch ukens bolig')
                }
            } catch (err) {
                setFilterState({
                    state: 'error',
                    errorMessage: (err as Error).message
                })
            }
        },
        [initialData, apiUrl]
    )

    useEffect(() => {
        const filtersBeforeSubscription = messageBus.peek('filter', 'updated')
        if (filtersBeforeSubscription) fetchData(filtersBeforeSubscription)

        messageBus.subscribe('filter', 'updated', fetchData)
        return () => {
            messageBus.unsubscribe('filter', 'updated', fetchData)
        }
    }, [fetchData])

    return filterState
}

function createQueryString(queryParams: object): URLSearchParams {
    const searchParams = new URLSearchParams()
    Object.entries(queryParams).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((item) => searchParams.append(key, item))
        } else {
            searchParams.append(key, value)
        }
    })
    return searchParams
}
