import React from 'react'
import { useFilterUpdated } from './hooks/useFilterUpdated'
import AdOfTheWeekWrapper from './AdOfTheWeekWrapper'
import { AdOfTheWeek } from './ukensBoligService'

export interface AppProps {
    ukensBolig: AdOfTheWeek
    loginId: string
    apiUrl: string
    favoriteApiUrl: string
    kvikkFinnEnabled: boolean
}

export const App = ({
    ukensBolig,
    loginId,
    apiUrl,
    favoriteApiUrl,
    kvikkFinnEnabled
}: AppProps) => {
    const filterState = useFilterUpdated(ukensBolig, apiUrl)

    switch (filterState.state) {
        case 'initial':
        case 'success':
            if (!filterState.ukensBolig) return null

            const {
                id: adId,
                canonical_url: canonicalUrl,
                main_search_key: searchKey
            } = filterState.ukensBolig.polePosition.searchEntry

            const { pulseTracking, searchEntry } =
                filterState.ukensBolig.polePosition

            return (
                <AdOfTheWeekWrapper
                    id={adId}
                    favoriteApiUrl={favoriteApiUrl}
                    canonicalUrl={canonicalUrl!}
                    searchKey={searchKey!}
                    paidPromotions={pulseTracking.paidPromotions}
                    realestateAd={searchEntry}
                    companyProfile={filterState.ukensBolig.companyProfile}
                    loginId={loginId}
                    labels={ukensBolig.polePosition.labels}
                    kvikkFinnEnabled={kvikkFinnEnabled}
                />
            )

        default:
            return null
    }
}
