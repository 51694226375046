import React, { createElement } from 'react'
import { hydrate } from 'react-dom'
import { App } from './src/App'
import { decode } from '@finn-no/safe-props'

class UkensboligPodlet extends HTMLElement {
    constructor() {
        super()
        try {
            const props = UkensboligPodlet.getProps()
            const el = document
                .querySelector('ukensbolig-podlet-isolated')
                .shadowRoot.getElementById('ukensbolig-podlet')
            hydrate(createElement(App, props), el)
        } catch (e) {
            console.error('err', e)
        }
    }

    static getProps() {
        const element = document
            ?.querySelector('ukensbolig-podlet-isolated')
            ?.shadowRoot?.getElementById('ukensbolig-podlet')

        return decode(element.dataset.props)
    }
}

if (!customElements.get('ukensbolig-podlet-isolated')) {
    customElements.define('ukensbolig-podlet-isolated', UkensboligPodlet)
}
