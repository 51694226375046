import pulse from '@finn-no/pulse-sdk'
import { PaidPromotion } from './fetchPolePosition'

type Type = 'View' | 'Click'

export type TrackParams = {
    adId: number
    paidPromotions: PaidPromotion[]
    trackType: Type
    size: 'SMALL' | 'MEDIUM' | 'LARGE'
}

export function track({ adId, paidPromotions, trackType, size }: TrackParams): void {
    try {
        pulse.trackEvent(
            {
                type: trackType,
                intent: 'Open',
                name: `Search result ${
                    trackType === 'Click' ? 'clicked' : 'viewed'
                }`,
                object: {
                    paidPromotions,
                    rank: 0, // Ukens bolig is always in the top position
                    id: adId.toString(),
                    deliveryCapability: 'ub',
                    type: 'ListingItem',
                    pageType: 'search-results',
                    name: 'Search result',
                    isTopPositionPremiumFeature: true,
                    paidPromotionTypes: ['fus'],
                    ukensBoligSize: size,
                    page: {
                        id: 'sdrn:finn:content:https://www.finn.no/realestate/homes/search.html',
                        type: 'Content',
                        url: 'https://www.finn.no/realestate/homes/search.html'
                    }
                },
                vertical: {
                    name: 'realestate',
                    subVertical: 'distribution'
                }
            },
            {
                resolveOnQueued: true
            }
        )
    } catch (e) {
        console.log(e)
    }
}
