import {
    LegendaryRealestateSearchAd as RealestateSearchAd,
    LegendaryAdOfTheWeekSearchAd as AdOfTheWeekSearchAd
} from '@schibsted-nmp/search-frontend-components/react/legendary'
import { AdOfTheWeekKvikkFinn } from '@schibsted-nmp/search-frontend-components/react'
import type {
    SearchKey,
    RealestateResultEntry
} from '@schibsted-nmp/search-frontend-clients/quest'
import React, { useCallback } from 'react'
import SaveAd from './SaveAd'
import { track } from './tracking'
import {
    PaidPromotionDistribution,
    PaidPromotionProductSales
} from './fetchPolePosition'
import { CompanyProfile } from './fetchCompanyProfile'

interface Props {
    id: string
    canonicalUrl: string
    searchKey: SearchKey
    realestateAd: RealestateResultEntry
    loginId: string
    labels: string[]
    paidPromotions: (PaidPromotionDistribution | PaidPromotionProductSales)[]
    favoriteApiUrl: string
    companyProfile?: CompanyProfile
    kvikkFinnEnabled: boolean
}

const fallbackImageWidth = 640
const basicImageSizes =
    '(min-width: 1300px) 210px, (min-width: 1025px) 215px, (min-width: 768px) 22vw, 33vw'
const plusImageSizes =
    '(min-width: 1300px) 310px, (min-width: 1025px) 320px, (min-width: 768px) 33vw, 50vw'
const premiumImageSizes =
    '(min-width: 1300px) 625px, (min-width: 1025px) 645px, (min-width: 768px) 645px, 100vw'

export default function AdOfTheWeekWrapper({
    id,
    canonicalUrl,
    searchKey,
    realestateAd,
    loginId,
    paidPromotions,
    favoriteApiUrl,
    companyProfile,
    kvikkFinnEnabled
}: Props) {
    const ukensBoligSize = realestateAd.flags?.includes(
        'highlight_listing_estate_premium'
    )
        ? 'LARGE'
        : realestateAd.flags?.includes('highlight_listing_estate_pluss')
        ? 'MEDIUM'
        : 'SMALL'

    const adId = Number(realestateAd.id)

    const labelText =
        searchKey === 'SEARCH_ID_REALESTATE_LEISURE_SALE'
            ? 'Ukens fritidsbolig'
            : searchKey === 'SEARCH_ID_REALESTATE_HOMES'
            ? 'Ukens bolig'
            : 'Betalt plassering'

    const renderFavorite = useCallback(() => {
        return (
            <SaveAd
                finncode={adId}
                size={ukensBoligSize}
                loginId={loginId}
                favoriteApiUrl={favoriteApiUrl}
            />
        )
    }, [realestateAd])

    const logo = companyProfile?.basicProfile?.logo ?? realestateAd.logo?.url
    const logoBackgroundColor =
        companyProfile?.extendedProfile?.colors?.logoBackgroundColor ??
        realestateAd.styling
            ?.find((s) => s.startsWith('highlight_colour'))
            ?.split(':')[1] ??
        '#fff'

    switch (ukensBoligSize) {
        case 'LARGE':
            return kvikkFinnEnabled ? (
                <AdOfTheWeekKvikkFinn
                    url={canonicalUrl}
                    ad={realestateAd}
                    footer={{ logo, logoBackgroundColor }}
                    id={id}
                    favorite={renderFavorite}
                    onClick={() =>
                        track({
                            adId,
                            paidPromotions,
                            trackType: 'Click',
                            size: 'LARGE'
                        })
                    }
                    onFirstInscreen={() =>
                        track({
                            adId,
                            paidPromotions,
                            trackType: 'View',
                            size: 'LARGE'
                        })
                    }
                    imageSizes={premiumImageSizes}
                    label={({ className = '', classes }) => (
                        <span
                            className={`${className} ${classes.badge} ${classes.info}`}
                        >
                            {labelText}
                        </span>
                    )}
                />
            ) : (
                <AdOfTheWeekSearchAd
                    url={canonicalUrl}
                    ad={realestateAd}
                    footer={{ logo, logoBackgroundColor }}
                    id={id}
                    favorite={renderFavorite}
                    onClick={() =>
                        track({
                            adId,
                            paidPromotions,
                            trackType: 'Click',
                            size: 'LARGE'
                        })
                    }
                    onFirstInscreen={() =>
                        track({
                            adId,
                            paidPromotions,
                            trackType: 'View',
                            size: 'LARGE'
                        })
                    }
                    imageSizes={premiumImageSizes}
                    label={({ className = '', classes }) => (
                        <span
                            className={`${className} ${classes.badge} ${classes.info}`}
                        >
                            {labelText}
                        </span>
                    )}
                />
            )
        default:
            return (
                <RealestateSearchAd
                    url={canonicalUrl}
                    ad={realestateAd}
                    searchKey={searchKey}
                    basicImageSizes={basicImageSizes}
                    plussImageSizes={plusImageSizes}
                    secondaryImageWidth={fallbackImageWidth}
                    id={id}
                    favorite={renderFavorite}
                    label={({ className = '', classes }) => (
                        <span
                            className={`${className} ${classes.badge} ${classes.info}`}
                        >
                            {labelText}
                        </span>
                    )}
                    onClick={() =>
                        track({
                            adId,
                            paidPromotions,
                            trackType: 'Click',
                            size: ukensBoligSize
                        })
                    }
                    onFirstInscreen={() =>
                        track({
                            adId,
                            paidPromotions,
                            trackType: 'View',
                            size: ukensBoligSize
                        })
                    }
                />
            )
    }
}
